import React, { Component } from "react";
import { reviews } from "../data";

const InfoContext = React.createContext();

//Provider
//Consumer
class InfoProvider extends Component {
  state = {
    reviews: reviews,
  }
  render() {
    return (
      <InfoContext.Provider
        value={{
          reviews: this.state.reviews,
          name: this.state.name,
          avatar: this.state.avatar,
          comment: this.state.comment
        }}
      >
        {this.props.children}
      </InfoContext.Provider>
    );
  }
}

const InfoConsumer = InfoContext.Consumer;

export { InfoProvider, InfoConsumer };
