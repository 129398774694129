
export const reviews = [
  {
    id: 1,
    name: 'John',
    avatar: '.././avatars/avatar-1.png',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, quaerat repellendus. Ad at sit laboriosam sint ea mollitia incidunt voluptatibus inventore libero dolorem velit dolor nobis odit tempora, ex consequuntur.'
  },
  {
    id: 2,
    name: 'Mike',
    avatar: '.././avatars/avatar-2.png',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, quaerat repellendus. Ad at sit laboriosam sint ea mollitia incidunt voluptatibus inventore libero dolorem velit dolor nobis odit tempora, ex consequuntur.'
  },
  {
    id: 3,
    name: 'Sofia',
    avatar: '.././avatars/avatar-3.png',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, quaerat repellendus. Ad at sit laboriosam sint ea mollitia incidunt voluptatibus inventore libero dolorem velit dolor nobis odit tempora, ex consequuntur.'
  },
  {
    id: 4,
    name: 'Joseph',
    avatar: '.././avatars/avatar-4.png',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, quaerat repellendus. Ad at sit laboriosam sint ea mollitia incidunt voluptatibus inventore libero dolorem velit dolor nobis odit tempora, ex consequuntur.'
  },
  {
    id: 5,
    name: 'Natasha',
    avatar: '.././avatars/avatar-5.png',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, quaerat repellendus. Ad at sit laboriosam sint ea mollitia incidunt voluptatibus inventore libero dolorem velit dolor nobis odit tempora, ex consequuntur.'
  }
];


