import React from 'react';
import logo from '../../link.png';
import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <Link className="navbar-brand ml-md-3" to="/">
      <img src={logo} alt="logo" style={{width:'120px'}}/>
  </Link>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span><i className="fas fa-bars" style={{color:'var(--mainNavy)'}}></i></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav ml-auto  text-center mr-lg-5">
      <li className="nav-item active">
        <Link className="nav-link text-dark text-uppercase" to="/">Home&nbsp;<i class="fas fa-home" /> <span class="sr-only">(current)</span></Link>
      </li>
      <li class="nav-item">
        <Link className="nav-link text-dark text-uppercase " to="/forms">Forms</Link>
      </li>
      <li class="nav-item">
        <Link className="nav-link text-dark text-uppercase " to="/about">About Us</Link>
      </li>
      
      
      <li class="nav-item">
        <Link className="nav-link text-dark text-uppercase" to="/contact">contact us</Link>
      </li>
    </ul>
    
  </div>
</nav>
    );
}
export default Navbar;