import React from 'react';
import styled from 'styled-components';

function Footer() {
    return (
        <FooterContainer>
            <div className="footer-middle">
            <div className="container">
                <div className="row">
                    {/* col 1 */}
                    <div className="col-md-3 col-sm-6 mx-auto text-center">
                        <h4>Contact Us</h4>
                        <ul className="list-unstyled">
                            <li>Phone: (888) 995-4952</li>
                            <li>Fax: (713) 588-2093</li>
                            <li>Mon-Fri: 8am to 7pm, CST</li>
                            <li>
5433 Westheimer Road, Suite 700
Houston, Texas 77056</li>
                        </ul>
                    </div>
                    
                </div>

                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <p className="text-center">
                        &copy;{new Date().getFullYear()} Link Funeral Funding - All Rights Reserved
                    </p>
                </div>
            </div>
            </div>
        </FooterContainer>
    )
}
export default Footer;

const FooterContainer = styled.footer`
.footer-middle {
    background: var(--mainNavy);
    padding-top: 3rem;
    color: var(--mainWhite);
}
.footer-bottom {
    padding-top: 3rem;
    padding-bottom: 2rem;
}
ul li a{
    color: var(--mainGrey);
}
ul li a:hover {
    color: var(--mainLightGrey);
}
`;