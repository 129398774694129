import React, { Component } from 'react'
import formpic1 from '../../client.jpg'
import formpic2 from '../../info.png'
import formpic3 from '../../POA.jpg'
import formpic4 from '../../POA2.jpg'

class Forms extends Component {
    render() {
        return (
            <div className="">
                <h2 className="text-center my-5">Forms</h2>
                <div class="row form-links-row py-3">
            <div class="col-sm-10 col-md-5 col-xl-3 my-3">
              <div class="card">
                <img class="card-img-top form-pic1" src={formpic1} alt="Card image cap"  />
                <div class="card-body">
                  <h5 class="card-title">New Client ACH Form</h5>
                  <p class="card-text">
                  This one-time form is used to set up Link Funeral Funding
                    accounts with client funeral homes.
                  </p>
                  <a href="../../../images/New-client-ACH-form.pdf"
                target="_blank"
                download class="btn btn-primary">
                    Download
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-10 col-md-5 col-xl-3 my-3">
              <div class="card">
                <img class="card-img-top  form-pic1" src={formpic2} alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title">Information Form</h5>
                  <p class="card-text">
                  This form is used to share decedent, policy and beneficiary
                    information.
                  </p>
                  <a href="../../../images/Info-page.pdf" target="_blank" download class="btn btn-primary">
                    Download
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-10 col-md-5 col-xl-3  my-3">
              <div class="card">
                <img class="card-img-top form-pic1" src={formpic3} alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title">Irrevocable Assignment and Power of Attorney</h5>
                  <p class="card-text">
                  This assignment form is used so that the beneficiary may
                    assign a specific dollar amount of the policy to the funeral
                    home. Must be notarized.
                  </p>
                  <a href="../../../images/assignment-page-2.pdf"
                target="_blank"
                download class="btn btn-primary">
                    Download
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-10 col-md-5 col-lx-3 my-3">
              <div class="card">
                <img class="card-img-top  form-pic1" src={formpic4} alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title">Irrevocable Reassignment and Power of Attorney</h5>
                  <p class="card-text">
                  This reassignment form is used so that the funeral home may
                    reassign a specific dollar amount of the referenced policy
                    to Link Funeral Funding. Must be notarized.
                  </p>
                  <a href="../../../images/Reassignment-page-1.pdf"
                target="_blank"
                download class="btn btn-primary">
                    Download
                  </a>
                </div>
              </div>
            </div>
          </div>
               
            </div>
            
            
        );
    }
}
export default Forms;