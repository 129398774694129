import React, { Component } from 'react'

export default class FAQ extends Component {
    render() {
        return (
            <div className="container p-5 faqpage">
                <h2 className="text-center pb-5">Frequently Asked Questions</h2>
                <div className="col-10 col-md-8">
                    <h4>Where is Link Funeral Funding located?  </h4>
                    <p>Link Funeral Funding is a privately-owned Houston, Texas based company.  We provide funeral homes and cemeteries nationwide, a fast, easy and reliable processing of life insurance assignments</p>
                    <hr />
                </div>
                <div className="col-10 col-md-8">
                    <h4>Why should I use Link Funeral Funding?  </h4>
                    <p>We strive to make our clients happy by completely eliminating the challenges faced when working with insurance companies. One of the greatest challenges Funeral Home’s face in assignment processing is speed of verification. Our team verifies the insurance information promptly. What this means to the funeral home or cemetery is the ability to use the decedent’s insurance to pay the funeral or cemetery bill.</p>
                    <hr />
                </div>
                <div className="col-10 col-md-8">
                    <h4>What separates Link Funeral Funding from other funding companies? </h4>
                    <p>  Verifications are handled by our licensed funeral directors.  Our in-house team of Funeral Directors is one of the key components of our operation which sets us apart from our competitors. Our Funeral Directors know the right questions to ask to get the policies verified quickly.   
                    </p>
                    <hr />
                </div>
                <div className="col-10 col-md-8">
                    <h4>How do I submit a claim?</h4>
                    <p> Complete the decedent information page, assignment page and reassignment
form with notarization where applicable.  Send those documents to Link Funeral Funding along with the funeral contract signed by all assignees and with a copy of their ID. We will not
disclose your funeral contract to any outside party other than the insurance company. <br /><br />

There are 2 different ways to submit a funding request
1: Email your request to faxes@linkfuneralfunding.com
2: Fax your request to 713-588-2093<br /><br />

Your claims representative will contact you to let you know if a claim form
is required and/or if additional documentation is needed. You may contact
us if you need a claim form, we have a library of readily available claim
forms.
   
                    </p>
                    <hr />
                </div>
                <div className="col-10 col-md-8">
                    <h4>How soon will I receive my payment after I submit a claim?</h4>
                    <p>    We provide immediate payment.  You can expect a payment to be made to your funeral home/cemetery within 24 hours of verification (Some insurance companies may have lengthier procedures than others, in these rare cases we will notify you.)
                    </p>
                    <hr />
                </div>
                <div className="col-10 col-md-8">
                    <h4>How does Link Funeral Funding pay me?</h4>
                    <p>  Most funeral homes/cemeteries are set up for ACH direct payment. This method is FREE and the fastest way to receive your funds. We can mail a check to the funeral home/cemetery if preferred.  
                    </p>
                    <hr />
                </div>
                <div className="col-10 col-md-8">
                    <h4>How will I know I have been paid?</h4>
                    <p> Our team will fax or email the funeral home/cemetery an ACH confirmation when the payment transaction has been completed.   
                    </p>
                    <hr />
                </div>
                <div className="col-10 col-md-8">
                    <h4>Will Link Funeral Funding charge me a fee?</h4>
                    <p>  Link Funeral Funding offers a competitive funding fee of 3.25% (we can match our competitor’s fee)  
                    </p>
                    <hr />
                </div>
                <div className="col-10 col-md-8">
                    <h4>Does link Funeral Funding advance additional proceeds above the funeral costs to the family?</h4>
                    <p>  We will advance complete policy funds when additional funds are needed (including travel expenses, medical bills or other immediate needs).  The fee for processing the excess amount will be 5%.    
                    </p>
                    <hr />
                </div>
                <div className="col-10 col-md-8">
                    <h4>How does the family get paid the excess?</h4>
                    <p>    The most common method of payment to the family is a check payable to the beneficiary which is mailed to the funeral home to present to the family (FedEx overnight option available). If preferred, a check can be mailed directly to the beneficiary or funds can be direct deposited to the family.
                    </p>
                    <hr />
                </div>
                <div className="col-10 col-md-8">
                    <h4>Do I need to send Link Funeral Funding original assignments and paperwork?</h4>
                    <p>    In some case we will need original document.  Our team will let you know if we will require original documentation.  If you have received a request for original documents to be sent to us, please mail all original paperwork to Link Funeral Funding.  
                    </p>
                    <hr />
                </div>
               
            </div>
        )
    }
}
