import React, { Component } from 'react'

class NotFoundPage extends Component {
    render() {
        return (
            <div>
                <h2>NotFoundPage Page</h2>
            </div>
        )
    }
}
export default NotFoundPage;