import React, { Component } from 'react'

class About extends Component {
    render() {
        return (
            <div className="container aboutpage">
                <div className="row">
                    <h2 className="text-center pt-5 ">
                        Our Team
                    </h2>
                </div>
                <hr />
                <div className="row profile text-center align-items-center">
                    <div className="col-12 col-lg-4 mx-auto">
                        <img src="../../images/Markus.jpg"></img>
                        <h3 className="p-3">Markus Nassar</h3>
                        <h4>Co-founder</h4>
                    </div>
                    <div className="col-md-8 col-sm-12 mx-auto">
                        <p className="text-left">
                        Markus is a true global citizen with decades of experience in the management of companies across the world.  He attended college in Marburg, Germany, then attended Boston University, where he earned his Master’s Degree in Administrative Studies.<br />

As the world economy  began changing in the late 1990’s, he noticed the  travel needs of business professionals around the world changed, too.  He expanded the family travel company into rapidly-expanding business centers in Asia, Germany, Dubai and Jordan.<br />

Not one to stop there, Markus opened his first Jimmy Johns in 2007, and currently own 13 locations in the Houston area, as well as real estate investments, and various joint ventures around the country. 

Markus is fluent in English, German, Italian, French and Arabic.<br />
                        </p>
                    </div>

                </div>
                <hr />
                <div className="row profile text-center align-items-center">
                    <div className="col-12 col-lg-4 mx-auto">
                        <img src="../../images/Nadim.jpg"></img>
                        <h3 className="p-3">Nadim Cook</h3>
                        <h4>Co-founder</h4>
                    </div>
                    <div className="col-md-8 col-sm-12 mx-auto">
                        <p className="text-left">
                        Nadim graduated from Sugarland Dulles High School in 1985, and attended the University of Houston – Main Campus, where he earned a degree in Economics.  In 1993, he graduated from South Texas College of Law.   In 1994, he co-founded a civil litigation firm  which handled all aspects of personal injury, family and commercial litigation/transactions.  In 1998, he opened his own commercial transaction and personal injury firm, where he currently practices.  Nadim also owns a medical staffing company which staffs Licensed Surgical Assistants to numerous surgeons and facilities in the Houston area. <br />

Nadim is very proud to be a UH Cougar, and supports all of its sports teams and various academic endeavors.  Most weekends, Nadim can be found at the Greater Houston Gun Club shooting sporting clays. 

Nadim is fluent in English and Arabic.</p>
                    </div>

                </div>
                
            </div>
        )
    }
}
export default About;