import React, { Component } from 'react'
import { Link } from 'react-router-dom'
class Contacts extends Component {
    state = {
        name: '',
        email:'',
        phone:'',
        message:'',
        emailstatus:''
    }
    //handle change
    handleChange = input => e => {
        this.setState({
            [input]: e.target.value
        });
    }

    //submit form
    submitForm = (e) => {
        const {
            name,
            email,
            phone,
            message,
        } = this.state;
        //console.log(this.state);
        //create a new XHTMLRequest
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {
            //update email status with response
console.log(xhr.responseText);

        });

        xhr.open('GET', "http//api.ruvictor.com/sendmail/index.php?sendto=" + email +
         '&name=' + name + 
         '&phone=' + phone +
         '$message=' + message);

//send the request
         xhr.send();

        e.preventDefault();

    }
    render() {
        const {
            name,
            email,
            phone,
            message,
        } = this.state;
        return (
            <section className="my-5 py-5">
                <div className="container">
                    <div className="well well-sm">
                       <h3><strong>Our Location</strong></h3> 
                    </div>
                
                <div className="row">
                    <div className="col-md-7">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3464.3575902049292!2d-95.47370318553952!3d29.7383624819947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c1605aa35249%3A0x9c12331b6d8e163e!2s5433%20Westheimer%20Rd%20%23700%2C%20Houston%2C%20TX%2077056!5e0!3m2!1sen!2sus!4v1592945646530!5m2!1sen!2sus"
                    style={{
                        border: '0',
                        width: '100%',
                        height: '315px',
                        frameborder: '0'
                    }} 
                    allowFullScreen
                    />
                    </div>
                    <div className="col-md-5 contact">
                    <h4><strong>Contact Us</strong></h4>
                        <ul >
                            <li>Phone: (888) 995-4952</li>
                            <li>Fax: (713) 588-2093</li>
                            <li>Mon-Fri: 8am to 7pm, CST</li>
                            <li>
5433 Westheimer Road, Suite 700
Houston, Texas 77056</li>
                        </ul>
                    </div>
                    {/* <div className="col-md-5">
                        <h4><strong>Contact Us</strong></h4>
                        <form onSubmit={this.submitForm}>
                            <div className="form-group">
                                <input type="text" className="form-control"
                                 placeholder="Name" value={name} onChange={this.handleChange('name')}/>
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control"
                                 placeholder="Email" value={email} onChange={this.handleChange('email')}/>
                            </div>
                            <div className="form-group">
                                <input type="tel" className="form-control"
                                 placeholder="Phone" value={phone} onChange={this.handleChange('phone')}/>
                            </div>
                            <textarea cols="30" rows="3" placeholder="Message" value={message} className="form-control" onChange={this.handleChange('message')}/>
                            <Link className="btn btn-outline-primary text-uppercase mt-1">
                                <i className="fa fa-paper-plane-o" aria-hidden="true" />
                                <i className="fab fa-telegram-plane"/>
                                &nbsp;Send
                            </Link>
                        </form>
                    </div> */}
                </div>
                </div>
            </section>
        )
    }
}
export default Contacts;