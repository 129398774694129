import React, { Component } from "react";
import { InfoConsumer } from "../context";
import { Link } from "react-router-dom";
import formpic1 from '../../client.jpg'
import formpic2 from '../../info.png'
import formpic3 from '../../POA.jpg'
import formpic4 from '../../POA2.jpg'
import { Parallax, Background } from "react-parallax";
class Home extends Component {
  render() {
    return (
      <section>
        <Parallax strength={700}>
          <div
            className="hero-container"
            style={{ background: `rgba(255, 255, 255, .3)` }}
          >
            <div className="hero container">
              <h1 className="display-4 text-center">
                <strong>Link Funeral Funding</strong>
              </h1>
              <p className="lead text-center hide-small">
                A Texas-based company established to provide the most
                competitive rates and personalized service to our funeral home
                clients.
              </p>
            </div>
          </div>

          <Background className="custom-bg">
            <img
              src="https://miro.medium.com/max/10368/1*IKv4MsCXCF88FXPDJOuOKw.jpeg"
              alt="fill murray"
              className="hero-img"
            />
          </Background>
        </Parallax>
        {/* <div className="jumbotron jumbotron-fluid hero">
          <div className="container mt-5">
            <h1 className="display-4 text-center">
              <strong>Link Funeral Funding</strong>
            </h1>
            <p className="lead text-center hide-small">
              A Texas-based company established to provide the most competitive
              rates and personalized service to our funeral home clients.
            </p>
          </div>
        </div> */}
        <div className="container m-0 px-5 pt-4 pb-1 col-12 bg-light hide-large">
          <h5 className="text-center"> A Texas-based company established to provide the most
                competitive rates and personalized service to our funeral home
                clients.</h5>
<hr />
        </div>
        <div className="container m-0 p-0 col-12">
          <div className="row justify-content-center pt-4 bg-light">
            <div className="col-md-8 col-sm-12 p-3">
              <Link className="form-link">
                <h2 className="text-center">About Us</h2>
              </Link>
              <p className="text-center" id="aboutus">
                Our verification team is comprised of licensed funeral directors
                who know first-hand the importance of accurately verifying
                insurance polices in a timely manner, and will help you get the
                funding you need faster.
              </p>
            </div>
          </div>
          <div className="row justify-content-center p-5 bg-light about-row">
            <div className="col-md-4 col-sm-10 px-0 icon">
              <i className="fas fa-paperclip fa-5x"></i>
              <h3 className="text-center mb-3">
                We handle all of the paperwork/claims processing!
              </h3>
            </div>
            <div className="col-md-4 col-sm-10 px-0 icon">
              <i className="fas fa-clock fa-5x"></i>
              <h3 className="text-center">
                Most funding requests are verified and credited to your bank
                account in 24 hours.
              </h3>
            </div>
            <div className="col-md-4 col-sm-10 px-0 icon">
              <i className="fas fa-money-bill-wave fa-5x"></i>
              <h3 className="text-center">
                We will fund families up to the full amount of their insurance
                policy, allowing them access to funds immediately.
              </h3>
            </div>
          </div>
          {/* <div class="row form-links-row py-3">
            <div class="col-sm-10 col-md-5 col-xl-3 col-lg-5 my-3">
              <div class="card">
                <img class="card-img-top form-pic" src={formpic1} alt="Card image cap"  />
                <div class="card-body">
                  <h5 class="card-title">New Client ACH Form</h5>
                  <p class="card-text">
                  This one-time form is used to set up Link Funeral Funding
                    accounts with client funeral homes.
                  </p>
                  <a href="../../../images/New-client-ACH-form.pdf"
                target="_blank"
                download class="btn btn-primary">
                    Download
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-10 col-md-5 col-xl-3 col-lg-5 my-3">
              <div class="card">
                <img class="card-img-top  form-pic" src={formpic2} alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title">Information Form</h5>
                  <p class="card-text">
                  This form is used to share decedent, policy and beneficiary
                    information.
                  </p>
                  <a href="../../../images/Info-page.pdf" target="_blank" download class="btn btn-primary">
                    Download
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-10 col-md-5 col-xl-3 col-lg-5  my-3">
              <div class="card">
                <img class="card-img-top form-pic" src={formpic3} alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title">Irrevocable Assignment and Power of Attorney</h5>
                  <p class="card-text">
                  This assignment form is used so that the beneficiary may
                    assign a specific dollar amount of the policy to the funeral
                    home. Must be notarized.
                  </p>
                  <a href="../../../images/assignment-page-2.pdf"
                target="_blank"
                download class="btn btn-primary">
                    Download
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-10 col-md-5 col-lx-3 col-lg-5 my-3">
              <div class="card">
                <img class="card-img-top  form-pic" src={formpic4} alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title">Irrevocable Reassignment and Power of Attorney</h5>
                  <p class="card-text">
                  This reassignment form is used so that the funeral home may
                    reassign a specific dollar amount of the referenced policy
                    to Link Funeral Funding. Must be notarized.
                  </p>
                  <a href="../../../images/Reassignment-page-1.pdf"
                target="_blank"
                download class="btn btn-primary">
                    Download
                  </a>
                </div>
              </div>
            </div>
          </div> */}

           <div className="row links">
            <div className="col-xl-3 col-md-5 col-10">
              <Link
                to="../../../images/New-client-ACH-form.pdf"
                target="_blank"
                download
              >
                <div className="top">
                  <a
                    href="../../../images/New-client-ACH-form.pdf"
                    className="form-link"
                    target="_blank"
                    download
                  >
                    <h3>New Client ACH Form</h3>
                  </a>
                  <p>
                    This one-time form is used to set up Link Funeral Funding
                    accounts with client funeral homes.
                  </p>
                </div>
              </Link>
              <div className="bottom1"></div>
            </div>

            <div className="col-xl-3 col-md-5 col-10">
              <Link to="../../../images/Info-page.pdf" target="_blank" download>
                <div className="top">
                  <a
                    href="../../../images/Info-page.pdf"
                    className="form-link"
                    target="_blank"
                    download
                  >
                    <h3>Information Form</h3>
                  </a>
                  <p>
                    This form is used to share decedent, policy and beneficiary
                    information.
                  </p>
                </div>
              </Link>
              <div className="bottom2"></div>
            </div>
            <div className="col-xl-3 col-md-5 col-10">
              <Link
                to="../../../images/assignment-page-2.pdf"
                target="_blank"
                download
              >
                <div className="top">
                  <a
                    href="../../../images/assignment-page-2.pdf"
                    className="form-link"
                    target="_blank"
                    download
                  >
                    <h3>Irrevocable Assignment and Power of Attorney</h3>
                  </a>
                  <p>
                    This assignment form is used so that the beneficiary may
                    assign a specific dollar amount of the policy to the funeral
                    home. Must be notarized.
                  </p>
                </div>
              </Link>
              <div className="bottom3"></div>
            </div>
            <div className="col-xl-3 col-md-5 col-10">
              <Link
                to="../../../images/Reassignment-page-1.pdf"
                target="_blank"
                download
              >
                <div className="top">
                  <a
                    href="../../../images/Reassignment-page-1.pdf"
                    className="form-link"
                    target="_blank"
                    download
                  >
                    <h3>Irrevocable Reassignment and Power of Attorney</h3>
                  </a>
                  <p>
                    This reassignment form is used so that the funeral home may
                    reassign a specific dollar amount of the referenced policy
                    to Link Funeral Funding. Must be notarized.
                  </p>
                </div>
              </Link>
              <div className="bottom4"></div>
            </div>
          </div> 
          <div className="row test">
            <div className="col-md-6 col-sm-12 test-case">
              <Link className="form-link">
                <h2 className="text-center my-3 underline">Client Testimonial</h2>
              </Link>
              <p className="testimonial col-sm-12">
                Over the years we have used several funding companies to verify
                and fund our insurance assignments. Most of them could not live
                up to what they claimed they could do. I can truly say that Link
                Funeral Funding has gone above and beyond the call of duty.
                Their staff is both knowledgeable and professional. They are
                very fast and accurate, which is very important for cash flow
                opportunities. My only regret is that I wished that I had begun
                using them sooner!{" "}
              </p>{" "}
              <p className="testimonial">
                {" "}
                ~ Otis Sr. & Jacqueline Wiley O. W. Wiley Mortuary, Inc.
                Houston, Texas
              </p>
            </div>
            <div className="col-md-6 col-sm-12 p-0">
              <div className="container col-12 nopad p-0">
                <div className="team m-0 p-3 px-5">
                  <h2 className="text-center underline">Our Team</h2>
                  <p>
                    Link is comprised of an experienced team of capable
                    individuals. Click here to view our Team members.
                  </p>
                  <Link className="btn btn-outline-primary center" to="/about">
                    <i className="fas fa-eye" />
                    &nbsp;View
                  </Link>
                </div>
                <div className="faq m-0 p-3 px-5">
                  <h2 className="text-center underline">Frequently Asked Questions</h2>
                  <p>
                    Here is a full list of questions our clients are likley to
                    have and our answeres to them.
                  </p>
                  <Link className="btn btn-outline-primary center" to="/faq">
                    <i className="fas fa-eye" />
                    &nbsp;View
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Home;
